<template>
    <div class="content-wrapper">
        <div class="left-content">
            <div class="about-me">
                <h1>{{ username }}</h1>
                <div class="details">
                    <span class="about-item">{{ email }}</span>
                    <span class="about-item">Member Since {{ joined }}</span>
                </div>
                <div class="activity">
                    <span class="about-item">Total Downloads: {{ downloads }}</span>
                    <span class="about-item">Total Uploads: {{ uploads }}</span>
                </div>
                <button class="btn-edit about-item" @click="toggleContent">Edit Account</button>
            </div>
            <div class="account-nav">
                <div class="account-nav-wrapper">
                    <button class="account-nav-link active" @click="toggleContent">Overview</button>
                    <button class="account-nav-link" @click="toggleContent">Favorites</button>
                </div>
            </div>
        </div>
        <div class="right-content">
            <div id="overview" class="account-content">
                <div class="welcome-container">
                    <h1>Contribute!</h1>
                    <p>Each map contributed helps the community and spreads the love of D&D.</p>
                    <router-link to="/account" class="btn">Upload</router-link>
                </div>
                <div class="image-container">
                    <img :src="`${ profilePic }`" class="profile-pic" alt="profile pic" />
                    <p>{{ name }}</p>
                </div>
            </div>
            <div id="favorites" class="account-content">
                <div class="welcome-container">
                    <h1>Favorites</h1>
                    <p>It looks like you haven't saved any favorites yet.</p>
                    <router-link to="/gallery" class="btn">Explore Gallery</router-link>
                </div>
            </div>
            <div id="edit-account" class="account-content">
                <div class="welcome-container">
                    <h1>Edit Account</h1>
                    <p>Make changes to your account below.</p>
                </div>
                <div class="form-container">
                    <form @submit.prevent>
                        <label>Name</label>
                        <input v-model="name" type="text">
                        <label>Username</label>
                        <input v-model="username" type="text">
                        <label>Email</label>
                        <input v-model="email" type="text">
                        <va-button type="submit" class="btn-form">Save</va-button>
                    </form>
                </div>
            </div>

        </div>
    </div>
    
</template>

<script>
export default {
    name: 'Account',
    data() {
        return {
            username: "beholderjr",
            name: "Beholder Jr",
            email: "lastsamurai@earth.com",
            joined: "1/2/34",
            downloads: "420",
            uploads: "69",
            profilePic: "https://i.pinimg.com/564x/59/55/6d/59556dcf688b682c88eb5e8d3c0366ec.jpg",
        }
    },
    methods: {
        toggleContent (e) {
            let activeLink = e.target
            let activeDiv = document.getElementById(`${activeLink.innerText.toLowerCase().replace(/\s+/g, '-')}`)
            const navLinks = document.querySelectorAll(".account-nav-link")
            const contentDivs = document.querySelectorAll(".account-content")

            navLinks.forEach(link => {
                link.classList.remove("active")
            })
            contentDivs.forEach(div => {
                div.style.display = "none"
            })

            if (activeDiv.style.display != "block") { 
                activeDiv.style.display = "block"
            } 
            if (activeLink.closest(".account-nav-wrapper")) {
                    activeLink.classList.add("active")
            }
        },
    },
}
</script>

<style scoped>
h1 {
    font-size: 24px;
}

.content-wrapper {
    display: flex;
    gap: 20px;
}

.left-content {
    flex: 1;
}
    .about-me {
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        max-width: 300px;
        overflow: hidden;
        gap: 10px;
        border-radius: 8px;
        margin-bottom: 20px;
        padding: 20px;
    }
        .about-me * {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 3px 0 3px 0;
        }
        .about-item {
            font-size: 12px;
            line-height: 14px;
        }
        .btn-edit {
            color: #4E7AE7;
            font-weight: bold;
            background: transparent;
            border: none;
        }
        .btn-edit:hover {
            cursor: pointer;
            filter: brightness(1.2)
        }

    .account-nav {
        background-color: white;  
        border-radius: 8px;
        max-width: 300px;
    }
        .account-nav-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 20px 10px 20px 0px;
            gap: 10px;
        }
            .account-nav-link {
                padding: 10px 30px 10px 20px;
                width: 100%;
                background: transparent;
                border: none;
                font-weight: bold;
            }
            .account-nav-link:hover {
                cursor: pointer;
            }
            .active {
                border-left: 3px solid #4E7AE7;
                border-top-right-radius: 48px;
                border-bottom-right-radius: 48px;
                padding-left: 17px;
                font-weight: normal;
                background-color: #e5e5e5;
            }


.right-content {
    flex: 3;
}
    #overview {
        display: block;
    }
    #favorites,
    #edit-account {
        display: none;
    }

    .welcome-container {
        background-color: #4E7AE7;
        border-radius: 16px;
        padding: 30px 20px 10px 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #fff;
    }
        .welcome-container > p {
            margin: 20px 0 20px 0;
        }
        .btn {
            align-self: flex-end;
            color: #fff;
            padding: 10px;
            background: transparent;
            border: 1px solid white;
            border-radius: 46px;
        }

    .image-container {
        margin: 40px;
    }
        .profile-pic {
            height: 250px;
            border-radius: 50%;
        }
        .image-container > p {
            font-weight: bold;
            margin: 10px;
        }


    .form-container {
        display: flex;
        justify-content: center;
    }
        .btn-form {
        margin-top: 20px;
        align-self: center;
    }

form {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 50%;
    padding: 40px 0 40px 0;
}
label {
    margin: 10px 0 2px 0;
}
    
</style>